import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page inexistante" />
    <h1>404 - Page inexistante</h1>
    <p>
        La page que vous recherchez n'existe pas.
        <Link to="/mentions-legales">Cliquez-ici</Link> pour retourner sur la page d'accueil.


    </p>
  </Layout>
)

export default NotFoundPage
